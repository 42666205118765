@import 'styles/_variables.css';
@import 'styles/_fonts.css';

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: 'Roboto', 'OpenSans', Arial, Helvetica, sans-serif;
  overflow-y: scroll;
}

a {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
  transition: all var(--transitionTime);
}

  a:hover {
    text-decoration: underline;
  }

.textEnhanced {
  color: var(--turquoise);
  font-size: 1.8rem;
  font-weight: 600;
}

.textWarning {
  color: var(--orange);
  font-size: 1.7rem;
  font-weight: 600;
}



/* GENERIC CLASSES TO AVOID CREATING STYLED COMPONENTS */
.modalPicturesContainer img {
  max-width: 100%;
  max-height: 200px;
  margin: 1rem auto 0 auto;
}


body .reactour__helper {
  max-width: 371px;
}

/* UTILS */
.mr1 {
  margin-right: 0.8rem;
}

.ml1 {
  margin-left: 0.8rem;
}


/*
  SPECIFIC MATERIAL DESIGN CSS
  Prefixed with "body" to have priority over default material css
  and so that it works within a modal
*/

/* MODALS */
body .MuiDialog-paper {
  min-width: var(--wrapperWidthMin);
}

body .MuiDialog-paper form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body .MuiDialogTitle-root {
  color: var(--white);
  background: var(--blue);
}

body .MuiDialogTitle-root.errorModal {
  background: var(--red);
}

body .MuiDialogTitle-root.warningModal {
  background: var(--orange);
}

body .MuiDialogTitle-root.confirmModal {
  background: var(--green);
}

body .MuiDialogContent-root {
  padding-top: 16px;
}

/* TOGGLE BUTTONS */
body .MuiToggleButton-root {
  height: auto;
  min-height: 40px;
  min-width: 90px;
  color: var(--blue);
  border-color: var(--blue);
}

body .MuiToggleButton-root.Mui-selected {
  color: var(--white);
  background-color: var(--blue);
}

body .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--blue);
}

body .MuiToggleButton-root svg,
body .MuiDialogTitle-root svg {
  margin-right: 1rem;
}

/* TOOLTIPS */
body .MuiTooltip-tooltip {
  font-size: 1.4rem;
}

/* STEPPER */
body .MuiStepIcon-root.MuiStepIcon-active,
body .MuiStepLabel-label.MuiStepLabel-active {
  color: var(--turquoise);
  font-weight: 600;
}

/* GENERIC */

body .MuiTouchRipple-child {
  color: var(--blue-light);
}

body .MuiTableCell-head {
  font-weight: 600;
}

@media (min-width: 600px) {
  body .MuiTab-root {
    min-width: auto;
  }
}


@media (max-width: 540px) {
  body .MuiStep-horizontal {
    padding-left: 4px;
    padding-right: 4px;
  }

  body .MuiToggleButton-root {
    font-size: 1.1rem;
  }

  body .MuiTableCell-sizeSmall {
    padding: 6px 12px 6px 6px;
  }

  body .MuiTab-root {
    font-size: 1.2rem;
  }
}


/*
  SPECIFIC CHARTS CSS
*/
#stocksDashboard .recharts-layer.recharts-bar-rectangle {
  cursor: pointer;
}
