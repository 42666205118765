@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

/* =======
FONT ICON
======= */
@font-face {
  font-family: 'databilan';
  src:  url('./fonts/databilan.eot?hyrest');
  src:  url('./fonts/databilan.eot?hyrest#iefix') format('embedded-opentype'),
    url('./fonts/databilan.ttf?hyrest') format('truetype'),
    url('./fonts/databilan.woff?hyrest') format('woff'),
    url('./fonts/databilan.svg?hyrest#databilan') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'databilan', 'OpenSans', Arial, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: sub;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-container-03 .path1:before {
  content: "\fdb14";
}
.icon-container-03 .path2:before {
  content: "\fdb15";
  margin-left: -1em;
}
.icon-container-03 .path3:before {
  content: "\fdb16";
  margin-left: -1em;
}
.icon-container-03 .path4:before {
  content: "\fdb17";
  margin-left: -1em;
}
.icon-container-03 .path5:before {
  content: "\fdb18";
  margin-left: -1em;
}
.icon-container-13 .path1:before {
  content: "\fdb19";
}
.icon-container-13 .path2:before {
  content: "\fdb1a";
  margin-left: -1em;
}
.icon-container-13 .path3:before {
  content: "\fdb1b";
  margin-left: -1em;
}
.icon-container-13 .path4:before {
  content: "\fdb1c";
  margin-left: -1em;
}
.icon-container-13 .path5:before {
  content: "\fdb1d";
  margin-left: -1em;
}
.icon-container-23 .path1:before {
  content: "\fdb20";
}
.icon-container-23 .path2:before {
  content: "\fdb21";
  margin-left: -1em;
}
.icon-container-23 .path3:before {
  content: "\fdb22";
  margin-left: -1em;
}
.icon-container-23 .path4:before {
  content: "\fdb23";
  margin-left: -1em;
}
.icon-container-23 .path5:before {
  content: "\fdb24";
  margin-left: -1em;
}
.icon-stocks:before {
  content: "\fdb6";
}
.icon-create-intervention:before {
  content: "\fdb2";
}
.icon-hazard:before {
  content: "\fdb12";
}
.icon-leak-detector:before {
  content: "\fdb9";
}
.icon-container:before {
  content: "\fdb5";
}
.icon-assistant:before {
  content: "\fdb3";
}
.icon-intervention:before {
  content: "\fdb4";
}
.icon-scan:before {
  content: "\fdb11";
}
.icon-send-by-mail:before {
  content: "\fdb7";
}
.icon-send-by-letter:before {
  content: "\fdb8";
}