:root {
  /* COLORS */
  --blue: #0279AC;
  --blue-dark: #005c83;
  --blue-light: #1aa3dd;

  --tecnea-blue: #0092d5;

  --turquoise: #148075;
  --turquoise-dark: #096158;
  --turquoise-light: #22b4a6;

  --green: #008011;
  --green-light: #64c071;
  --green-dark: #005f0d;
  --green-darker: #003d08;

  --violet: #9B3CB7;
  --violet-light: #c265df;
  --violet-dark: #771e92;
  --violet-darker: #520968;

  --red: #E6000B;
  --red-dark: #af010a;

  --orange: #d8530d;
  --orange-light: #fc9b67;
  --orange-dark: #b84000;
  --orange-darker: #852e00;

  --yellow: #FCBF20;

  --grey: #716D84;
  --grey-dark: #2D264C;
  --grey-darker: #130F26;
  --grey-light: #AEACB7;
  --grey-lighter: #f8f8f8;

  --black: #000000;
  --white: #ffffff;


/* DO NOT DELETE AFTER HERE */

  /* RESPONSIVE WIDTHS */
  --wrapperWidthMax: 1200px;
  --wrapperWidthMin: 320px;
  --tabletWidth: 900px;

  /* FONTS */
  --fontOpenSans: 'OpenSans', Arial, Helvetica, sans-serif;
  --fontRoboto: 'Roboto', Arial, Helvetica, sans-serif;

  /* MISC */
  --borderRadius: 3rem;
  --transitionTime: 0.3s;

  --header-item-width: 160px;
  --header-height: 6rem;

  --zindexSmall: 10;
  --zindexMedium: 50;
  --zindexBig: 100;
  --zindexBigger: 200;
}

@media (max-width: 768px) {
  :root {
    --header-item-width: auto;
  }
}